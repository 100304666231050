h1,p,.move{
  opacity: 0;
  position: relative;
  bottom: -40px;
  transition:  all 1s ease-out;
}
.show{
  opacity: 1;
  bottom: 0;
}
.moveDelay:nth-of-type(1) .show{
  transition-delay: .5s;
}
.moveDelay:nth-of-type(2) .show{
  transition-delay: 1s;
}
.moveDelay:nth-of-type(3) .show{
  transition-delay: 1.5s;
}

.container{
  max-width: 80%;
  margin: auto;padding:50px 0;
  
}.h1{
  font-size: 2em;
  margin: 10px;

}.fw-5{font-weight: 500;}
.img-hr-line-zigzag{
  object-fit:fill;
  width:25%; 
  /* background-image: url('./assets/line\ zigzag.png');
  background-repeat: repeat-x !important;
  background-size: auto 6px;
  background-position: 50%; */
  margin:30px auto;
}.center{
  margin: auto;
}


body{position: relative;}


:host {
  display: block;
  --video-width: 100%;
  --video-height: 300px;
}

@media screen and (min-width: 1024px) {
  :host {
    --video-width: 50%;
    --video-height: 450px;
  }
}
.container-img{
  width: auto;
  max-width: 100%;
    max-height: 250px;
  overflow: hidden;

}
#container {
  position: relative;
  width: var(--video-width);
  background-color: #cccccc;
  perspective: 300px;
}

#video-container {
  height: var(--video-height);
  max-height: var(--video-height);
  transform-style: preserve-3d;
  transform-origin: center left;
  transition: transform 0.8s ease-out;
}

#video-container.rear-view {
  transform: translate(100%) rotateY(180deg);
}

#front-camera, 
#rear-camera {
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#rear-camera {
  transform: rotateY(180deg);
}

#preview-container {
  position: relative;
}

video {
  width: 100%;
  object-fit: cover;
}

canvas {
  position: absolute;
  visibility: hidden;
}

#video-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 3;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

material-button {
  cursor: pointer;
  display: block;
}
#close-video {
  position: absolute;
  top: 0;
  right: 0;
}

#record-video,
#stop-record-video {
  --font-color: #ff0000;
}

#download a {
  color: #000000;
  text-decoration: none;
}
#download a i {
  display: block;
  width: 100%;
  height: 100%;
}