
.section {
    padding: 100px 0;
    position: relative;
}

.m-15px-tb {
    margin-top: 15px;
    padding-bottom: 15px;
}
.contact-info h4 {
    color: #0b0b13;
}

.contact-info h4 {
    font-size: 34px;
  
    font-weight: 500;
}

.contact-info p {
    font-size: 16px;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}.contact-info ul {
    margin: 0;
    padding: 10px 0 0;
    list-style: none;
}

.contact-info li {
    font-size: 18px;
    font-weight: 500;
   
    line-height: 1.3;
    position: relative;
}.media {
    display: flex!important;
    align-items: flex-start;
}.icon {
    width: 20px;
    text-align: center;
    color: #ff9301;
    font-size: large;
    font-weight: 600;
}.contact-info li span {
    padding-left: 15px;
}
.contact-form .form-control {
    
    background: none;
    border: 1px solid hsla(0,0%,100%,.3);
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    height: calc(2em + 0.75rem + 2px);
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}.textField {
    color: #0b0b13;
    border: 1px solid rgba(11,11,19,.3);
    background: #fff;
}.google-map{ border: 5px solid #fff;
    width: 100%;    margin-top: 50px;

} iframe {
    width: 100%;
    height: 350px;
    border: none!important;
}h3{
    margin-bottom: 20px;
}p{
    color: #6b6b6b;
}