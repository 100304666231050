.nav-down {
  margin: 0;
  height: auto;
  padding: 0px ;
}
.block{  width: 100%;
display: flex ;
text-align: center;
justify-content: center;
}.pr-10{
  padding-right: 10%;
}
.left-bg {
  text-align: left;
  display: flex;
min-height: 100vh;

justify-content: center;
padding-left: 5rem;
margin: auto;
  align-items: start ;
  flex-direction: column !important;
}
.p-header {
  /* padding-left: 20%; */
  width: 100%;
}

.text-small {
  font-size: 2rem;
  color: #222;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.left-bg .col .-txt {
  display: flex;
  text-align: left;
}

.left-bg .block-txt {
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
/* padding-left: 40px; */
  text-align: left;
  font-size: 3rem;
  font-weight: 600;
}

.left-bg .wrapper {
  display: flex;
}

.left-bg .wrapper .static-txt {
  font-size: 3rem;
  font-weight: 600;
}
.left-bg .dynamic-txts {
  display: block;

  line-height: 90px;
  height: 90px;
  overflow: hidden;
}
.left-bg .dynamic-txts li {
  text-align: left;
  list-style: none;
  color: #ff9301;
  font-size: 2.5rem;
  font-weight: 500;
  position: relative;

  top: 0;
  animation: slide 10s steps(48) infinite;
  -webkit-animation: slide 10s steps(48) infinite;
}

.col {
  column-count: 1;
}
.col-text {
  max-width: 600px;
  width: 100% ;

  display: block;
}

.static-txt {
  font-size: 3rem;
  font-weight: 600;
}
@keyframes slide {
  0% {
    transform: translate(0px, 45px);
  }
  2% {
    transform: translate(0px, -25px);
  }
  33% {
    transform: translate(0px, -25px);
  }
  35% {
    transform: translate(0px, -145px);
  }
  66% {
    transform: translate(0px, -145px);
  }
  70% {
    transform: translate(0px, -270px);
  }
  95% {
    transform: translate(0px, -270px);
  }

  100% {
    transform: translate(0px, -290px);
  }
}
.content {
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: left;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: start;
}
.navbar {
  position: fixed;
  top:0px;
  z-index: 100;
  width: 100%;
 margin:0px auto ;
  font-size: medium;
  font-weight: 600;
  padding: 10px 0px;
  display: flex;
 flex-wrap: wrap ;
  justify-content: space-evenly;
  background-color: #ff9301;
  border: 0px;
  color: #fff;
  gap:1rem;
  
}

.img-profile-content {
  width: 100%;
  min-height: 50vh;
}
@media only screen and (max-width: 900px) {
  .img-profile,
  .img-profile-content {
    opacity: 0.5;
    z-index: -1;
    order: -1;
    position: relative !important;
    min-height: 100vh;
    height: auto;

    width: 100%;
    overflow: hidden;
  }.btn-header {

    justify-content: center;}
  .left-bg {   display: flex;
    justify-content: start;
    align-items: center;
    position: absolute;
    top: 100px;
    height: 100vh;
    padding-left: 0;
  }
  .content-header {
    display: flex;
    justify-content: start;
    align-items: center;
    top: 0;
    z-index: 1;
    order: 1;
    position: absolute;
    height: auto;
    background: none;
    width: 100%;
    min-height: 100vh;
  }.col-text{width: 80%;
  margin: auto;
text-align: center;}
  .img-profile-content {
    min-width: 450px;

    /* min-height: 100vh; */
    /* width: 100vw; */
  }
  .p-header {
    padding-left: 0;
  }.left-bg .block-txt ,.left-bg .dynamic-txts li{
    text-align: center;
  }
}
.content-about {
    padding: 20px;
  }
.btn {
  background-color: #ff9301;
  padding: 15px 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(82, 79, 61, 0.1), 0 0 0 0 rgba(255, 208, 0, 0);
  border: none;
}
.btn:hover {
  color: #ff7c01;
  background: none;
 box-shadow: 0px 0px 10px 2px #ff7c01;
}

.btn:active {
  color: #a85f00;
  background: none;
  box-shadow: 0px 0px 10px 2px #ff7c01;
}

.btn-header {
  padding: 20px 0px;
  display: flex;
}
.navbar-content a:hover{
  color: #fff;
}