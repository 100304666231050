.siderbar-content{
    height: 100vh; 
    
    
}
.logoStyle {
    font-size: 40px;
    width: 100%;
    height: 80px;
    background-color: #ff9301;
    color: #fff;
    text-align: center;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionStyle2 {
    width: 100%;
    top: 100px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.itemIcon {
    font-size: large;
   
 color: #000;
    text-align: center;
    margin: auto;
}
