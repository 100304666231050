.btn-time{
    padding: 5px 20px;
    background-color: #ff9301;
    color: white;
    border-radius: 5px;
    width: fit-content;
    margin:0px 20px;
}
CardHeader{
    font-weight:bolder;
}
p{
    margin: 10px 0;
}
.education{
    width: 100%;
    height: 100%; background-color: #F7F7FF; padding: 20px; 
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 1px;
}.edu{
    margin: 20px 0;
}
.edu div{
    margin: 5px 0;
    color: #555;

}.title{
    color: #000;
    font-weight: 700;
    font-size: large;
}.skill-lt {
    position: relative;
    padding-left: 80px;
}.skill-lt h6 {
    color: #0b0b13;    font-size: 15px;
    margin: 0 0 10px;
    font-weight: 400;
} .count-inner {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    /* color: #fff; */
    left: 0;
    top: 8px;
    position: absolute;
} .skill-bar {
    background: rgba(11,11,19,.1);   position: relative;
}
.skill-bar-in {
    width: 80px;
    position: relative;
    transition: all 1s ease;

    height: 10px;
    background: #ff9301;
animation: WidthMove 1s ;

}.bar,.bar div {width: 100%;

}.bar-list{
    width: 90%;
    margin-left:  auto;
}.skill-wrapper div{margin: 30px 0; }
@keyframes WidthMove{
    0%{width: 0%;}
    75%{width: 100%;}
}
.styleBorderRight{
    border-right: 1px solid #bbb; margin: 20px auto;
}
@media (max-width:750px) {
    .styleBorderRight{
        border-right: none;}
}